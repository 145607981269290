import React, { useState } from "react";
import { connect } from "react-redux";
import {
    Button,
    Modal,
    Col,
    Row,
    Spinner,
    InputGroup,
    Form,
} from "react-bootstrap";
import clsx from "clsx";
import {
    Switch, FormControlLabel,
    Checkbox } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable';
import ModalSearchUserSelector from './ModalSearchUserSelector';
import {
    searchUserInAzureForReassign
} from "../../actions/creators/userRequests"
import request from "../../reducers/request";

const ModalReassignEmail = ({
    showModal,
    setShowModal,
    requestId,
    requestData,
    loadingModal,
    setLoadingModal,
    updateRequestForReassignPrimaryEmail,
    isEditable,
    fetchData,
    IsRequest,
    //Redux creators
    searchUserInAzureForReassign
}) => {

    let optionsForUserIdList = [];
    const [savedComments, setSavedComments] = useState("");
    const [selectedPrimaryEmail, setSelectedPrimaryEmail] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [totalUsers, setTotalUsers] = useState(-1);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [isUrgentFlag, setIsUrgentFlag] = useState(false);
    const prepareDropDownLabel = (object) => {
        return "ID:" + object.userId + ", " + object.name + " " + object.lastName + ", " + object.email;
    }
    
    const SingleValue = props => (
        <components.SingleValue {...props}>
            {props.data.chipLabel}
        </components.SingleValue>
    );

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const SaveEmail = evt => {
        if (evt) {
            if (evt.__isNew__) {
                evt.chipLabel = evt.value;
            }
            setSelectedPrimaryEmail(evt)
        }
        else {
            setSelectedPrimaryEmail([]);
        }
    }

    const searchInputValue = (inputValue) => {
        setShowModal(false);
        searchUserInAzureForReassign(
            inputValue.trim(),
            setUsersList,
            setTotalUsers,
            setShowSearchModal,
            setLoadingSearch,
            setShowModal
        );
    };

    const loadInitialData = () => {
        if (requestData.reassignedPrimaryEmail != "" && requestData.statusId == 1) {
            setSelectedPrimaryEmail({ value: requestData.reassignedPrimaryEmail, label: requestData.reassignedPrimaryEmail, chipLabel: requestData.reassignedPrimaryEmail });
        }
    }

    const prepareDataForForm = () => {
        if (requestData && requestData.allUsers) {
            requestData.allUsers.forEach(function (value, i) {
                if (requestData.allUsers[i].isChild == false) {
                    let optionlabel = prepareDropDownLabel(requestData.allUsers[i]);
                    optionsForUserIdList.push({ value: requestData.allUsers[i].userId, label: optionlabel, chipLabel: requestData.allUsers[i].email });
                }        
            });
            optionsForUserIdList = optionsForUserIdList.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
        }   

    };

    const useStyles = makeStyles({
        root: {
            "&:hover": {
                backgroundColor: "transparent",
            },
        },
        icon: {
            borderRadius: 3,
            width: 30,
            height: 30,
            boxShadow:
                "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
            backgroundColor: "#f5f8fa",
            backgroundImage:
                "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
            "$root.Mui-focusVisible &": {
                outline: "2px auto rgba(19,124,189,.6)",
                outlineOffset: 2,
            },
            "input:hover ~ &": {
                backgroundColor: "#ebf1f5",
            },
            "input:disabled ~ &": {
                boxShadow: "none",
                background: "rgba(206,217,224,.5)",
            },
        },
        checkedIcon: {
            backgroundColor: "red",
            backgroundImage:
                "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
            "&:before": {
                display: "block",
                width: 30,
                height: 30,
                backgroundImage:
                    "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                    " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                    "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
                content: '""',
            },
            "input:hover ~ &": {
                backgroundColor: "red",
            },
        },
    });
    const CustomCheckbox = () => {
        const classes = useStyles();

        return (
            <Checkbox
                className={classes.root}
                color="default"
                checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                icon={<span className={classes.icon} />}
                checked={isUrgentFlag}
                onChange={() => {
                    setIsUrgentFlag(!isUrgentFlag);
                }}
            />
        );
    };

    prepareDataForForm();

    return (
        <React.Fragment>
            <ModalSearchUserSelector
                showSearchModal={showSearchModal}
                setShowSearchModal={setShowSearchModal}
                usersList={usersList}
                setUsersList={setUsersList}
                totalUsers={totalUsers}
                setSelectedPrimaryEmail={setSelectedPrimaryEmail}
                setShowModal={setShowModal}
            />
        <Modal
                show={showModal}
                onHide={() => {
                    setLoadingModal(true);
                    setShowModal(false);
                    setSavedComments("");
                    setSelectedPrimaryEmail([]);
                }}
                onEntered={loadInitialData}
        >
            <Modal.Header closeButton>
                <h5>Reassignment of Non-Personal User Email</h5>
            </Modal.Header>
            <Modal.Body>
                {loadingModal ? (
                    <React.Fragment>
                        <Row className="text-center">
                            <Col xs={12}>
                                <Spinner animation="border" size="lg" />
                            </Col>
                        </Row>
                    </React.Fragment>
                ) : (
                        <React.Fragment>

                                <Row className="text-center">
                                    {!requestData.isInternal ? (
                                        <Col xs={12}>
                                        <h6>This function should only be used to reassign records using a non-personal platform email address to a new responsible party.  All records using this non-personal platform email will be assigned to the new user upon approval of the request.  </h6>
                                        </Col>
                                    ) : (
                                        <Col xs={12}>
                                        <h6>This function should only be used to reassign records using a non-personal platform email address to a new internal responsible party.  All records using this non-personal platform email will be assigned to the new user upon approval of the request.  </h6>
                                    </Col>
                                    )}
                               
                            </Row>

                            <Row className="text-center" style={{ marginTop: "20px" }}>
                                    <Col xs={12}>
                                        The Platform Email <b>{requestData ? requestData.email : ""}</b>, Currently assigned to <b>{requestData ? requestData.requestUser.email : ""}</b> with name <b>{requestData ? requestData.requestUser.fullName : ""}</b>
                                </Col>
                            </Row>
                                <Row className="text-center" style={{ marginTop: "20px" }}>
                                    {requestData.isInternal ? (
                                        <Col xs={12} className="pb-3">
                                            <Form.Label>
                                                New User<b style={{ color: "red" }}>*</b>:
                                            </Form.Label>

                                            <CreatableSelect
                                                isClearable
                                                defaultValue={requestData.reassignedPrimaryEmail && requestData.statusId == 1 ? [{ value: requestData.reassignedPrimaryEmail, label: requestData.reassignedPrimaryEmail, chipLabel: requestData.reassignedPrimaryEmail }] : selectedPrimaryEmail}
                                                options={optionsForUserIdList}
                                                components={{ SingleValue }}
                                                onChange={SaveEmail}
                                                formatCreateLabel={(inputValue) => `Search "` + inputValue + `"`}
                                                onCreateOption={(inputValue) => searchInputValue(inputValue)}
                                            />
                                        </Col>
                                    ) : (
                                        <Col xs={12} className="pb-3">
                                            <Form.Label>
                                                New User<b style={{ color: "red" }}>*</b>:
                                            </Form.Label>

                                                <CreatableSelect
                                                    isClearable
                                                    defaultValue={requestData.reassignedPrimaryEmail && requestData.statusId == 1 ? [{ value: requestData.reassignedPrimaryEmail, label: requestData.reassignedPrimaryEmail, chipLabel: requestData.reassignedPrimaryEmail }] : selectedPrimaryEmail}
                                                    options={optionsForUserIdList}
                                                    onChange={SaveEmail}
                                                />
                                        </Col>
                                    )}
                                </Row>
                            <Row className="text-left">
                                <Col xs={12}>
                                    <div className="mt-3 text-left" />
                                    <Form.Group>
                                        <Form.Label>Comments<b style={{ color: "red" }}>*</b>:</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows="3"
                                            defaultValue={savedComments}
                                            onChange={(evt) => setSavedComments(evt.target.value)}
                                            maxLength={500}
                                        />
                                    </Form.Group>
                                    </Col>
                                    <Col xs={12} md={{ span: 6, offset: 2 }} className="text-right">
                                        <FormControlLabel
                                            control={<CustomCheckbox />}
                                            label={"Urgent Request"}
                                        />
                                    </Col>
                            </Row>


                        </React.Fragment>
                    )}

            </Modal.Body>
            {!loadingModal ? (
                <Modal.Footer>
                    <Button
                        variant="danger"
                        onClick={() => {
                            setLoadingModal(true);
                            setShowModal(false);
                            setSavedComments("");
                            setSelectedPrimaryEmail([]);
                        }}
                    >
                        Cancel
                </Button>
                    <Button
                         onClick={() => {
                            if (selectedPrimaryEmail == null || selectedPrimaryEmail == undefined || selectedPrimaryEmail == "") {
                                alert("Please enter New User Email");
                            }
                            else if (!validateEmail(selectedPrimaryEmail.chipLabel.toLowerCase())) {
                                alert("Please enter valid New User Email");
                            }
                            else if (
                                !savedComments || // Check if comments is null, undefined, or empty
                                savedComments.replace(/\s/g, "").length < 4 || // Must have at least 4 characters excluding whitespace
                                (savedComments.length >= 4 && savedComments.replace(/[^a-zA-Z]/g, "").length < 4) // Exactly 4 characters must be alphanumeric
                            ) {
                                alert("Your request is missing a valid Comment for access. Please provide a clear and valid reason for why the access is needed.");
                            }
                            else if (selectedPrimaryEmail.chipLabel.toLowerCase() == requestData.requestUser.email.toLowerCase())
                            {
                                alert("You can't select the same user to reassign this request.");
                            }
                            else {
                                updateRequestForReassignPrimaryEmail(
                                    {
                                        requestId: requestId,
                                        IsRequest: IsRequest || false,
                                        comments: savedComments,
                                        ReassignedPrimaryEmail: selectedPrimaryEmail.chipLabel,
                                        IsReassignedRequest: true,
                                        IsUrgent: isUrgentFlag,
                                        IsInternal : requestData.isInternal
                                    },
                                    fetchData,
                                    setShowModal,
                                    setLoadingModal,
                                    setSelectedPrimaryEmail,
                                    setSavedComments
                                );
                                setLoadingModal(true);
                            }
                        }
                        }
                    >
                        Submit Request
                </Button>
                </Modal.Footer>
            ) : (
                    <div></div>
                )
            }
            </Modal >
            </React.Fragment>
    );

};
export default connect(null, {
    searchUserInAzureForReassign
})(ModalReassignEmail);