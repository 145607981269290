import React, { useState } from "react";
import {
    Button,
    Modal,
    Col,
    Row,
    Spinner,
    InputGroup,
    Form,
} from "react-bootstrap";
import clsx from "clsx";
import {
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Divider,
    TextField,
    FormControlLabel,
    Checkbox,
    Switch
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CustomDropdownButton from "../../UI/CustomDropdownButton/CustomDropdownButton";
import DatePicker from "react-datepicker";
import { FaRegCalendarAlt } from "react-icons/fa";
import CreatableSelect from 'react-select/creatable';
import TooltipForComponent from "../../UI/Tooltip/TooltipForComponent";

const ModalModifyMultipleRequest = ({
    showModal,
    setShowModal,
    requestId,
    requestData,
    loadingModal,
    setLoadingModal,
    updatemultiplerequest,
    isEditable,
    fetchData,
    IsRequest,
    showCommentBox,
    selectedrequestIds
}) => {



    const platformUserType = requestData.platformUserTypeList
        ? requestData.platformUserTypeList.map((a) => a.description)
        : [];


    const platformUserTypeId = requestData.platformUserTypeList
        ? requestData.platformUserTypeList.map((a) => a.platformUserTypeId)
        : [];

    const [isUrgentFlag, setIsUrgentFlag] = useState(false);
    const [selectedPlatformUserType, setSelectedPlatformUserType] = useState(-1);

    const changeSelection = (evt) => {
        setSelectedPlatformUserType(evt);
    };

    const [updateState, setUpdateState] = useState(true);
    const [savedComments, setSavedComments] = useState("");

    const popupstyle = {
        padding: "10px",
    };
    const useStyles = makeStyles({
        root: {
            "&:hover": {
                backgroundColor: "transparent",
            },
        },
        icon: {
            borderRadius: 3,
            width: 30,
            height: 30,
            boxShadow:
                "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
            backgroundColor: "#f5f8fa",
            backgroundImage:
                "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
            "$root.Mui-focusVisible &": {
                outline: "2px auto rgba(19,124,189,.6)",
                outlineOffset: 2,
            },
            "input:hover ~ &": {
                backgroundColor: "#ebf1f5",
            },
            "input:disabled ~ &": {
                boxShadow: "none",
                background: "rgba(206,217,224,.5)",
            },
        },
        checkedIcon: {
            backgroundColor: "red",
            backgroundImage:
                "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
            "&:before": {
                display: "block",
                width: 30,
                height: 30,
                backgroundImage:
                    "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                    " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                    "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
                content: '""',
            },
            "input:hover ~ &": {
                backgroundColor: "red",
            },
        },
    });
    const CustomCheckbox = () => {
        const classes = useStyles();

        return (
            <Checkbox
                className={classes.root}
                color="default"
                checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                icon={<span className={classes.icon} />}
                checked={isUrgentFlag}
                onChange={() => {
                    setIsUrgentFlag(!isUrgentFlag);
                }}
            />
        );
    };
    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 45,
            height: 25,
            padding: 0,
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            "&$checked": {
                transform: "translateX(20px)",
                color: theme.palette.common.white,
                "& + $track": {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 20,
            height: 20,
            boxShadow: "none",
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 20,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);



    if (updateState) {
        setUpdateState(false);
        setSelectedPlatformUserType(
            platformUserTypeId.findIndex((x) => x === requestData.PlatformUserTypeId)
        );
    }

    return (
        <Modal
            show={showModal}
            onHide={() => {
                setUpdateState(true);
                setLoadingModal(true);
                setShowModal(false);
                setSavedComments("");

            }}
        >
            <Modal.Header closeButton>
                <h5><b>Change User Roles for Multiple Records</b></h5>
            </Modal.Header>
            <Modal.Body>

                {loadingModal ? (
                    <React.Fragment>
                        <Row className="text-center">
                            <Col xs={12}>
                                <Spinner animation="border" size="lg" />
                            </Col>
                        </Row>
                    </React.Fragment>
                ) : (

                        <React.Fragment>
                            <h6 className="text-center"><b>This user role change effects all user records selected</b> </h6>
                            <div style={popupstyle}></div>
                            <Row className="text-center">
                                <Col xs={12}><b>Current User Role:</b> {requestData.platformUserTypeName}</Col>
                                <div style={popupstyle}></div>
                                <Col xs={12}>
                                    <b>New User Role:</b>
                                    <CustomDropdownButton
                                        label=""
                                        id={`dropdown-modal-modify-request`}
                                        selected={
                                            selectedPlatformUserType === -1
                                                ? platformUserTypeId.findIndex(
                                                    (x) => x === requestData.platformUserTypeId
                                                )
                                                : selectedPlatformUserType
                                        }
                                        onSelect={changeSelection}
                                        items={platformUserType}
                                        disabled={!isEditable}
                                    />
                                </Col>

                            </Row>
                            <Row className="text-left">
                                {showCommentBox && (
                                    <Col xs={12}>
                                        <div className="mt-3 text-left" />
                                        <Form.Group>
                                            <Form.Label>Leave a comment<b style={{ color: "red" }}>*</b></Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows="3"
                                                value={savedComments}
                                                onChange={(evt) => setSavedComments(evt.target.value)}
                                                maxLength={500}
                                            />
                                        </Form.Group>
                                    </Col>
                                )}
                                <Col xs={12} md={{ span: 6, offset: 2 }} className="text-right">
                                    <FormControlLabel
                                        control={<CustomCheckbox />}
                                        label={"Urgent Request"}
                                    />
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
            </Modal.Body>
            {isEditable && !loadingModal ? (
                <Modal.Footer>
                    <Button
                        variant="danger"
                        onClick={() => {
                            setLoadingModal(true);
                            setShowModal(false);
                            setSavedComments("");

                        }}
                    >
                        Cancel
                 </Button>
                    <Button
                        onClick={() => {
                            if (showCommentBox) {

                                if (selectedPlatformUserType === -1) {
                                    alert("You can not select the same user role to change this request");
                                }
                               else if (requestData.platformUserTypeId === platformUserTypeId[selectedPlatformUserType]) {
                                    alert("You can not select the same user role to change this request");
                                }
                                else if (
                                    !savedComments || // Check if comments is null, undefined, or empty
                                    savedComments.replace(/\s/g, "").length < 4 || // Must have at least 4 characters excluding whitespace
                                    (savedComments.length >= 4 && savedComments.replace(/[^a-zA-Z]/g, "").length < 4) // Exactly 4 characters must be alphanumeric
                                ) {
                                    alert("Your request is missing a valid Comment for access. Please provide a clear and valid reason for why the access is needed.");
                                }

                                else {
                                    updatemultiplerequest(
                                        {
                                            requestId: requestId,
                                            platformUserTypeId:
                                                selectedPlatformUserType === -1
                                                    ? requestData.platformUserTypeId
                                                    : platformUserTypeId[selectedPlatformUserType],
                                            IsRequest: IsRequest || false,
                                            comments: savedComments,
                                            PlatformId: requestData.platformId,
                                            Requests: selectedrequestIds,
                                            IsUrgent: isUrgentFlag
                                        },
                                        fetchData,
                                        setShowModal
                                    );
                                    setLoadingModal(true);
                                    setSavedComments("");
                                }
                            }

                        }}
                    >
                        Submit Request
          </Button>
                </Modal.Footer>
            ) : (
                    <div></div>
                )}
        </Modal >
    );
};

export default ModalModifyMultipleRequest;
