import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import {
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Divider,
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import CustomTextField from "../../UI/CustomTextField/CustomTextField";
import CustomDropdownButtonM from "../../UI/CustomDropdownButton/CustomDropdownButtonM";
import {
    fetchRegionsApi,
    fetchMarketsApi,
    fetchAgencysApi,
    fetchPracticesApi,
    fetchPlatformsApi,
    fetchClientsApi,
    fetchPlatformOwner,
    setClientCodeAndAccount,
    getPlatformDataById,
    getAssetTypeDataByClientId
} from "../../../actions/creators/catalogs";
import {
    postAccessRequest,
    updateUserData,
    setComments,
    saveNewExternalUser,
    validateSpecialAccessRequest,
} from "../../../actions/creators/request";
import PlatformBusinessAccessConfirmationModal from "./ExtraComponents/Step3PlatformBusinessAccessConfirmationModal";
import PlatformDetailCards from "./ExtraComponents/Step3PlatformDetailCards";
import { MdAddCircleOutline } from "react-icons/md";
import AddAccountModal from "./ExtraComponents/Step3AddAccountModal";
import useForceUpdate from "use-force-update";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import { REQUEST_STEP_AT } from "../../../enums";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";

const Step2 = ({
    //Props
    activeStep,
    setActiveStep,
    requestStep,
    setRequestStep,
    setLoadingSubmitRequest,
    selectedRegion,
    setSelectedRegion,
    selectedMarket,
    setSelectedMarket,
    selectedAgency,
    setSelectedAgency,
    selectedPractice,
    setSelectedPractice,
    selectedPlatform,
    setSelectedPlatform,
    selectedNewPlatform,
    setSelectedNewPlatform,
    selectedClient,
    setSelectedClient,
    selectedClientCode,
    setSelectedClientCode,
    selectedOwnership,
    setSelectedOwnership,
    selectedProductCode,
    setSelectedProductCode,
    selectedAccounts,
    setSelectedAccounts,
    currentRequests,
    setCurrentRequests,
    isUrgentFlag,
    setIsUrgentFlag,
    //setRequestUserData,
    //setManagerData,
    //setUserEmailSelected,
    //Redux props
    regionsApi,
    marketsApi,
    agencysApi,
    practicesApi,
    platformsApi,
    clientsApi,
    clientCodeApi,
    platformOwnershipApi,
    isRequestForMe,
    isRequestForInternal,
    emailSelected,
    loggedUser,
    requestUser,
    manager,
    comments,
    //Redux creators
    fetchRegionsApi,
    fetchMarketsApi,
    fetchAgencysApi,
    fetchPracticesApi,
    fetchPlatformsApi,
    postAccessRequest,
    updateUserData,
    setComments,
    saveNewExternalUser,
    validateSpecialAccessRequest,
    fetchClientsApi,
    fetchPlatformOwner,
    setClientCodeAndAccount,
    getPlatformDataById,
    getAssetTypeDataByClientId,
}) => {
    const forceUpdateStep3 = useForceUpdate();
    const history = useHistory();
    useEffect(() => {
        fetchRegionsApi();
        fetchPlatformOwner();
    }, [fetchRegionsApi, fetchPlatformOwner]);

    //State for requestAccessFrom
    var regions = regionsApi.map((a) => a.description);
    var regionsId = regionsApi.map((a) => a.regionId);

    var markets = marketsApi.map((a) => a.description);
    var marketsId = marketsApi.map((a) => a.marketId);

    var agencies = agencysApi.map((a) => a.description);
    var agenciesId = agencysApi.map((a) => a.agencyId);

    var practices = practicesApi.map((a) => a.practice.description);
    var practicesId = practicesApi.map((a) => a.practice.practiceId);

    var platforms = platformsApi.map((a) => a.description);
    var platformsId = platformsApi.map((a) => a.platformId);

    var clients = clientsApi.map((a) => a.description);
    var clientsId = clientsApi.map((a) => a.clientId);

    var clientCode = clientCodeApi.map((a) => a.code);
    var clientCodeId = clientCodeApi.map((a) => a.clientCodeId);

    var ownership = platformOwnershipApi.map((a) => a.description);
    var ownershipId = platformOwnershipApi.map((a) => a.platformOwnerShipId);

    //States for "Special Business Access Platforms"
    const [loadingPlatforms, setLoadingPlatforms] = useState(false);
    const [platformId, setPlatformId] = useState(null);
    const [availablePlatforms, setAvailablePlatforms] = useState([]);
    const [
        availablePlatformsClientAccountName,
        setAvailablePlatformsClientAccountName,
    ] = useState([]);
    const [showBusinessAccessModal, setShowBusinessAccessModal] = useState(false);

    //State Add account modal
    const [showAddAccountModal, setShowAddAccountModal] = useState(false);

    const fetchClients = (index) => {
        fetchClientsApi(platformsId[index], agenciesId[selectedAgency]);
    };

    var selectedPlatformsTitle = currentRequests.map((a) => a.platform);
    var newPlatforms = [];
    platforms.map((singlePlatform, x) => {
        if (!selectedPlatformsTitle.includes(platforms[x])) {
            newPlatforms.push(singlePlatform);
        }
        return newPlatforms;
    });

    const stateDisplayElementsToUser = (level) => {
        switch (level) {
            case 1:
                setSelectedMarket(-1);
            /* falls through */
            case 2:
                setSelectedAgency(-1);
            /* falls through */
            case 3:
                setSelectedPractice(-1);
            /* falls through */
            case 4:
                setSelectedPlatform(-1);
                setSelectedNewPlatform(-1);
            /* falls through */
            case 5:
                setSelectedClient(-1);
                setSelectedOwnership(-1);
            /* falls through */
            case 6:
                setSelectedClientCode(-1);
            /* falls through */
            default:
                break;
        }
    };

    const onSelectRegion = (evt) => {
        evt = evt.target.value;
        setSelectedRegion(evt);
        fetchMarketsApi(regionsId[evt]);
        stateDisplayElementsToUser(1);
    };

    const onSelectMarket = (evt) => {
        evt = evt.target.value;
        setSelectedMarket(evt);
        fetchAgencysApi(marketsId[evt]);
        stateDisplayElementsToUser(2);
    };

    const onSelectAgency = (evt) => {
        evt = evt.target.value;
        setSelectedAgency(evt);
        fetchPracticesApi(agenciesId[evt]);
        stateDisplayElementsToUser(3);
    };

    const onSelectPractice = (evt) => {
        evt = evt.target.value;
        setSelectedPractice(evt);
        fetchPlatformsApi(practicesId[evt], agenciesId[selectedAgency]);
        stateDisplayElementsToUser(4);
    };

    const submitSpecialAccessRequest = (index, userIdProp) => {
        var data = {
            userId: isRequestForMe
                ? loggedUser.userId
                : userIdProp
                    ? userIdProp
                    : requestUser.userId,
            regionId: regionsId[selectedRegion],
            marketId: marketsId[selectedMarket],
            agencyId: agenciesId[selectedAgency],
            practiceId: practicesId[selectedPractice],
            platformId: platformsApi[index].platformId,
            email: emailSelected,
        };

        setPlatformId(platformsApi[index].platformId);

        setLoadingPlatforms(true);

        validateSpecialAccessRequest(
            data,
            (response) => {
                setLoadingPlatforms(false);
                if (((response.data.data.accessExistsToOtherEmails || response.data.data.accessExistsAsInactive) || (!response.data.data.accessExistsToOtherEmails && !response.data.data.accessExistsAsInactive))
                    && response.data.data.question && platformsApi[index].description.toLowerCase().includes("meta") && (isRequestForMe ? loggedUser.email != emailSelected : requestUser.email != emailSelected))
                    {
                        alert('You cannot create requests with a different email than your primary corporate email for META. Click ok to go back to Step 1 and select the users primary corporate email.');
                        handleBacktoStep1();
                    }
                else {
                    if (response.data.data.accessExistsToOtherEmails) {
                        var IsExist = window.confirm(response.data.data.accessExistsToOtherEmailsMessage);
                        if (IsExist) {
                            if (response.data.data.question) {
                                setAvailablePlatforms(response.data.data.accessLevel);
                                setAvailablePlatformsClientAccountName(
                                    response.data.data.clientAccount
                                );
                                setShowBusinessAccessModal(true); // shows the QUESTION
                            }
                            else {
                                if (response.data.data.accessExistsAsInactive) {
                                    if (!response.data.data.accessExistsAsInactiveCompleted) {
                                        alert(response.data.data.accessexistsMessage);
                                        setSelectedPlatform(-1);
                                    }
                                    else {

                                        var reqId = parseInt(response.data.data.accessexistsMessage.match(/\d+/g));
                                        var result = window.confirm(response.data.data.accessexistsMessage);
                                        if (result) {
                                            window.open('ChangeUserRecords?Id=' + reqId, '_blank');
                                            setSelectedPlatform(-1);
                                        }
                                        else {
                                            setSelectedPlatform(-1);
                                        }
                                    }
                                }
                                else {
                                    fetchClients(index);
                                }
                            }
                        }
                        else if (!IsExist)
                            setSelectedPlatform(-1);
                    }
                    else {
                        if (response.data.data.question) {
                            setAvailablePlatforms(response.data.data.accessLevel);
                            setAvailablePlatformsClientAccountName(
                                response.data.data.clientAccount
                            );
                            setShowBusinessAccessModal(true); // shows the QUESTION
                        }
                        else {
                            if (response.data.data.accessExistsAsInactive) {
                                if (!response.data.data.accessExistsAsInactiveCompleted) {
                                    alert(response.data.data.accessexistsMessage);
                                    setSelectedPlatform(-1);
                                }
                                else {

                                    var reqId = parseInt(response.data.data.accessexistsMessage.match(/\d+/g));
                                    var result = window.confirm(response.data.data.accessexistsMessage);
                                    if (result) {
                                        window.open('ChangeUserRecords?Id=' + reqId, '_blank');
                                        setSelectedPlatform(-1);
                                    }
                                    else {
                                        setSelectedPlatform(-1);
                                    }
                                }
                            }
                            else {
                                fetchClients(index);
                            }
                        }
                    }
                }
            },
            () => {
                setLoadingPlatforms(false);
            }
        );
    };

    const onSelectPlatform = (evt) => {
        setSelectedNewPlatform(false);
        stateDisplayElementsToUser(5);
        evt = evt.target.value;

        if (evt !== -1) {
            setSelectedNewPlatform(evt);
            let index = platforms.indexOf(newPlatforms[evt]); // gets index of selected platform
            let platform = platformsApi[index]; // get platform from redux
            setSelectedPlatform(index);
            //if (platform.description.toLowerCase().includes("meta") && (isRequestForMe ? loggedUser.email != emailSelected : requestUser.email != emailSelected)) {
            //    alert('You can not create requests with a different email than your primary corporate email for META. click ok to go back and select the primary corporate email.');
            //    handleBacktoStep1();
            //}
            //else {
                //getPlatformDataById(platformsApi[index].platformId);
                if (platform.description.toLowerCase().includes("meta") && (isRequestForMe ? !loggedUser.isInternal : !requestUser.isInternal)) {
                    alert('External users are no longer able to be invited to our Meta agency business managers. All access must be at the partner level with an approved TPA.');
                    history.push("/");
                }
                else {

                    if (platform.businessAccess) {
                        setLoadingPlatforms(true);
                        if (isRequestForMe || isRequestForInternal) {
                            updateUserData(isRequestForMe ? loggedUser : requestUser);
                            submitSpecialAccessRequest(index);
                        } else {
                            if (!requestUser.userId) {
                                saveNewExternalUser(
                                    emailSelected,
                                    requestUser,
                                    submitSpecialAccessRequest,
                                    index
                                );
                            } else {
                                updateUserData(requestUser);
                                submitSpecialAccessRequest(index);
                            }
                        }
                    } else {
                        fetchClients(index);
                    }
                }
            //}
        }
    };

    const onSelectClient = (evt) => {
        stateDisplayElementsToUser(6);
        evt = evt.target.value;
        setSelectedClient(evt);
        setClientCodeAndAccount(evt, clientsApi);
        getAssetTypeDataByClientId(clientsApi[evt].platformId, clientsApi[evt].clientId);
    };

    const onSelectClientCode = (evt) => {
        evt = evt.target.value;
        setSelectedClientCode(evt);
    };

    const onSelectOwnership = (evt) => {
        evt = evt.target.value;
        setSelectedOwnership(evt);
    };

    const handleChangeProductCode = (evt) => {
        setSelectedProductCode(evt.target.value);
    };

    const resetPlatformSectionValues = () => {
        setSelectedNewPlatform(-1);
        setSelectedPlatform(-1);
        setSelectedClient(-1);
        setSelectedClientCode(-1);
        setSelectedOwnership(-1);
        setSelectedProductCode("");
        setSelectedAccounts([]);
    };

    const submitAccessRequest = async (userIdProp) => {
        for (var i = 0; i < currentRequests.length; i++) {
            setLoadingSubmitRequest(true);
            var request = {
                isInternal: isRequestForMe
                    ? loggedUser.isInternal
                    : requestUser.isInternal,
                UserId: isRequestForMe
                    ? loggedUser.userId
                    : userIdProp
                        ? userIdProp
                        : requestUser.userId,
                Email: emailSelected,

                RegionId: regionsApi[selectedRegion].regionId,
                MarketId: marketsApi[selectedMarket].marketId,
                AgencyId: agencysApi[selectedAgency].agencyId,
                PracticeId: practicesApi[selectedPractice].practice.practiceId,
                PlatformId: currentRequests[i].PlatformId,
                ClientId: currentRequests[i].ClientId,
                ClientCodeId: currentRequests[i].ClientCodeId,
                ProductCode: currentRequests[i].ProductCode,
                PlatformOwnerShipId: 1,
                SharePointId: "",
                Comments: comments,
                ApprovedRejectedBy: null,
                RequestAccountAccess: currentRequests[i].RequestAccountAccess,
                ManagerEmail:
                    isRequestForMe || isRequestForInternal ? manager.mail : "",
                ManagerName:
                    isRequestForMe || isRequestForInternal ? manager.displayName : "",
                IsUrgent: isUrgentFlag,
            };
        
            await postAccessRequest(request, setLoadingSubmitRequest, setRequestStep/*, setActiveStep, setRequestUserData, setManagerData, setUserEmailSelected*/);
        }
    };
    
    const submitOnClickSaveUserOrUpdate = () => {
        if (
            !comments || // Check if comments is null, undefined, or empty
            comments.replace(/\s/g, "").length < 4 || // Must have at least 4 characters excluding whitespace
            (comments.length >= 4 && comments.replace(/[^a-zA-Z]/g, "").length < 4) // Exactly 4 characters must be alphanumeric
        ) {
            alert("Your request is missing a valid Comment for access. Please provide a clear and valid reason for why the access is needed.");
        }
        else {
            setLoadingSubmitRequest(true);

            setRequestStep(REQUEST_STEP_AT.REQUEST_SUCCESS);

            if (isRequestForMe || isRequestForInternal) {
                updateUserData(isRequestForMe ? loggedUser : requestUser);
                submitAccessRequest();
            } else {
                if (!requestUser.userId) {
                    saveNewExternalUser(emailSelected, requestUser, submitAccessRequest);
                } else {
                    updateUserData(requestUser);
                    submitAccessRequest();
                }
            }
        }
    };
    const handleBacktoStep1 = () => {
        setActiveStep(activeStep - 2);
        setRequestStep(requestStep - 2);
        setSelectedRegion(-1);
        setSelectedMarket(-1);
        setSelectedAgency(-1);
        setSelectedPlatform(-1);
        setSelectedPractice(-1);
       
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
        setRequestStep(requestStep - 1);
    };

    const decideButtonNextIsDisabled = () => {
        //Check necesary data to enable button.
        if (currentRequests.length > 0) {
            return false;
        }
        return true;
    };

    const CustomCheckbox = () => {
        const classes = useStyles();

        return (
            <Checkbox
                className={classes.root}
                color="default"
                checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                icon={<span className={classes.icon} />}
                checked={isUrgentFlag}
                onChange={() => {
                    setIsUrgentFlag(!isUrgentFlag);
                }}
            />
        );
    };

    const useStyles = makeStyles({
        root: {
            "&:hover": {
                backgroundColor: "transparent",
            },
        },
        icon: {
            borderRadius: 3,
            width: 30,
            height: 30,
            boxShadow:
                "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
            backgroundColor: "#f5f8fa",
            backgroundImage:
                "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
            "$root.Mui-focusVisible &": {
                outline: "2px auto rgba(19,124,189,.6)",
                outlineOffset: 2,
            },
            "input:hover ~ &": {
                backgroundColor: "#ebf1f5",
            },
            "input:disabled ~ &": {
                boxShadow: "none",
                background: "rgba(206,217,224,.5)",
            },
        },
        checkedIcon: {
            backgroundColor: "red",
            backgroundImage:
                "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
            "&:before": {
                display: "block",
                width: 30,
                height: 30,
                backgroundImage:
                    "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                    " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                    "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
                content: '""',
            },
            "input:hover ~ &": {
                backgroundColor: "red",
            },
        },
    });

    const Comments = <p>Comments<b style={{ color: "red" }}>*</b></p>

    return (
        <Container>
            <AddAccountModal
                showAddAccountModal={showAddAccountModal}
                setShowAddAccountModal={setShowAddAccountModal}
                selectedAccounts={selectedAccounts}
                setSelectedAccounts={setSelectedAccounts}
                selectedClientId={clientsId[selectedClient]}
                regionId={regionsId[selectedRegion]}
                marketId={marketsId[selectedMarket]}
                agencyId={agenciesId[selectedAgency]}
                practiceId={practicesId[selectedPractice]}
                platformId={platformsId[selectedPlatform]}
                email={emailSelected}
                userId={isRequestForMe
                        ? loggedUser.userId
                        : requestUser.userId}
                currentPathSelection={{
                    //Selected path Ids
                    selectedPlatformId: platformsId[selectedPlatform],
                    selectedClientId: clientsId[selectedClient],
                    selectedClientCodeId: clientCodeId[selectedClientCode],
                    selectedOwnershipId: ownershipId[selectedOwnership],
                    selectedProductCode: selectedProductCode,
                    //Descriptions
                    selectedPlatformDescription: platforms[selectedPlatform],
                    selectedClientDescription: clients[selectedClient],
                    selectedClientCodeDescription: clientCode[selectedClientCode],
                    selectedOwnershipDescription: ownership[selectedOwnership],
                    selectedPracticeDescription: practices[selectedPractice],
                }}
                currentRequests={currentRequests}
                setCurrentRequests={setCurrentRequests}
                resetPlatformSectionValues={resetPlatformSectionValues}
            />
            <PlatformBusinessAccessConfirmationModal
                showModal={showBusinessAccessModal}
                setShowModal={setShowBusinessAccessModal}
                currentPathSelection={{
                    selectedRegionId: regionsId[selectedRegion],
                    selectedMarketId: marketsId[selectedMarket],
                    selectedAgencyId: agenciesId[selectedAgency],
                    selectedPracticeId: practicesId[selectedPractice],
                    selectedPlatformDescription: platforms[selectedPlatform],
                    selectedAgencyDescription: agencies[selectedAgency],
                }}
                availablePlatforms={availablePlatforms}
                availablePlatformsClientAccountName={
                    availablePlatformsClientAccountName
                }
                manager={manager}
                user={isRequestForMe ? loggedUser : requestUser}
                platformId={platformId}
                platformOwnershipApi={platformOwnershipApi}
                emailSelected={emailSelected}
                setSelectedPlatform={setSelectedPlatform}
                setSelectedNewPlatform={setSelectedNewPlatform}
            />
            <Row style={{ marginLeft:"0px",paddingLeft:"74px"}}>
                <Col xs={12} md={5}>
                    <Row className="text-center pt-3">
                        <Col xs={12}>
                            <h4>Step 1. Platform Details</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} className="pt-3">
                            <CustomDropdownButtonM
                                label={
                                    <p>
                                        Region <b style={{ color: "red" }}>*</b>
                                    </p>
                                }
                                id="dropdown-region"
                                selected={selectedRegion}
                                onSelect={onSelectRegion}
                                items={regions}
                                disabled={currentRequests.length > 0 ? true : false}
                                tooltipText="Geographical location of the platform account."
                            />
                        </Col>
                        <Col xs={12} md={6} className="pt-3">
                            <CustomDropdownButtonM
                                label={
                                    <p>
                                        Market <b style={{ color: "red" }}>*</b>
                                    </p>
                                }
                                id="dropdown-market"
                                selected={selectedMarket}
                                onSelect={onSelectMarket}
                                items={markets}
                                disabled={
                                    currentRequests.length > 0
                                        ? true
                                        : selectedRegion === -1
                                            ? true
                                            : false
                                }
                                tooltipText="Name of the country in the geographical location where the platform account operates."
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} className="pt-3">
                            <CustomDropdownButtonM
                                label={
                                    <p>
                                        Agency <b style={{ color: "red" }}>*</b>
                                    </p>
                                }
                                id="dropdown-agency"
                                selected={selectedAgency}
                                onSelect={onSelectAgency}
                                items={agencies}
                                disabled={
                                    currentRequests.length > 0
                                        ? true
                                        : selectedMarket === -1
                                            ? true
                                            : false
                                }
                                tooltipText="Name of the agency that owns or manages the platform account."
                            />
                        </Col>
                        <Col xs={12} md={6} className="pt-3">
                            <CustomDropdownButtonM
                                label={
                                    <p>
                                        Practice <b style={{ color: "red" }}>*</b>
                                    </p>
                                }
                                id="dropdown-practice"
                                selected={selectedPractice}
                                onSelect={onSelectPractice}
                                items={practices}
                                disabled={
                                    currentRequests.length > 0
                                        ? true
                                        : selectedAgency === -1
                                            ? true
                                            : false
                                }
                                tooltipText="Name of the media buy type on the platform account."
                            />
                        </Col>
                    </Row>
                </Col>
                <Col md={1}>
                    <Divider
                        orientation="vertical"
                        style={{
                            backgroundColor: "#808080",
                            marginTop: 15,
                            marginLeft: "50%",
                        }}
                    />
                </Col>
                <Col xs={12} md={6}>
                    <Row className="text-center pt-3">
                        <Col xs={12}>
                            <h4>Step 2. Account Details</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={5} className="pt-3">
                            <ReactTooltip
                                id={"platform-dropdown-selector"}
                                backgroundColor="#FFB32C"
                                textColor="black"
                                place="right"
                                className="tooltip-width"
                            >
                                Name of the platform the user will need access to
                            </ReactTooltip>
                            <FormControl
                                data-tip
                                data-for={"platform-dropdown-selector"}
                                fullWidth
                                disabled={selectedPractice === -1 ? true : false}
                            >
                                {loadingPlatforms ? (
                                    <div className="text-center">
                                        <Spinner animation="border" />
                                    </div>
                                ) : (
                                    <React.Fragment>
                                        <InputLabel>
                                            Platform Name <b style={{ color: "red" }}>*</b>
                                        </InputLabel>
                                        <Select
                                            id={"dropdown-platforms"}
                                            value={selectedNewPlatform}
                                            onChange={onSelectPlatform}
                                        >
                                            <MenuItem value={-1}>
                                                <em>Select an option</em>
                                            </MenuItem>
                                            {newPlatforms.map((name, i) => (
                                                <MenuItem key={name} value={i}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </React.Fragment>
                                )}
                            </FormControl>
                        </Col>
                        <Col xs={12} md={5} className="pt-3">
                            <CustomDropdownButtonM
                                label={
                                    <p>
                                        Client Name <b style={{ color: "red" }}>*</b>
                                    </p>
                                }
                                id="dropdown-client"
                                selected={selectedClient}
                                onSelect={onSelectClient}
                                items={clients}
                                disabled={
                                    loadingPlatforms ? true : selectedPlatform === -1 ? true : false
                                }
                                tooltipText="Name assigned by finance department to a client where the user is requesting access."
                            />
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12} md={5} className="pt-3">
                            <CustomDropdownButtonM
                                label={
                                    <p>
                                        Client Code <b style={{ color: "red" }}>*</b>
                                    </p>
                                }
                                id="dropdown-client-code"
                                selected={selectedClientCode}
                                onSelect={onSelectClientCode}
                                items={clientCode}
                                disabled={selectedClient === -1 ? true : false}
                                tooltipText="Finance code assigned to a client for media buys."
                            />
                        </Col>
                        <Col xs={12} md={5} className="pt-3">
                            <CustomTextField
                                label="Product Code"
                                disabled={selectedPlatform === -1 ? true : false}
                                onChange={handleChangeProductCode}
                                inputProps={{
                                    maxLength: 20,
                                }}
                                tooltipText="Finance code assigned to a product of the client for media buys."
                                id="product-code"
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* Plus button to add accounts & table to show them*/}
            <Row>
                <Col xs={7} className="text-right pt-5">
                    <h4>Step 3. Add Account(s)</h4>
                </Col>
                <Col xs={4} className="text-left pt-5">
                    {currentRequests.length > 0 &&
                        selectedClientCode === -1 &&
                        selectedOwnership === -1 ? null : (
                        <React.Fragment>
                            {selectedClientCode !== -1 ? (
                                <React.Fragment>
                                    <ReactTooltip
                                        id={"step3-add-account-label"}
                                        backgroundColor="#FFB32C"
                                        textColor="black"
                                        place="top"
                                        className="tooltip-width"
                                    >
                                        List of the currently available Accounts that can be
                                        accessed for the client in the platform.
                                    </ReactTooltip>
                                    <MdAddCircleOutline
                                        data-tip
                                        data-for={"step3-add-account-label"}
                                        size={35}
                                        style={{ color: "rgb(135, 203, 156)" }}
                                        className="pointer-cursor"
                                        onClick={() => {
                                            selectedAccounts.push({
                                                AssetId: -1,
                                                PlatformUserTypeId: -1,
                                                ClientAccountsIds: [],
                                                isUnlimitedAccess: false,
                                                BeginningDate: null,
                                                ExpirationDate: null,
                                                AssetDescription: "",
                                                PlatformTypeDescription: "",
                                                ClientDescription: [],
                                            });
                                            setSelectedAccounts(selectedAccounts);
                                            setShowAddAccountModal(true);
                                        }}
                                    />
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <ReactTooltip
                                        id={"step3-plus-sign"}
                                        backgroundColor="#FFB32C"
                                        textColor="black"
                                        place="top"
                                        className="tooltip-width"
                                    >
                                        Account Details
                                    </ReactTooltip>
                                    <MdAddCircleOutline
                                        data-tip
                                        data-for={"step3-plus-sign"}
                                        size={35}
                                        style={{ color: "grey" }}
                                        className="pointer-cursor"
                                    />
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </Col>
                <Col xs={12} className="pt-5">
                    <PlatformDetailCards
                        currentRequests={currentRequests}
                        setCurrentRequests={setCurrentRequests}
                        forceUpdateStep3={forceUpdateStep3}
                    />
                </Col>
            </Row>
            {/* Comments and Urgent checkbox */}
            <Row className="pt-5">
                <Col xs={12} md={4} className="text-left">
                    <TextField
                        fullWidth
                        id="platform-business-access-modal-comments"
                        label={Comments}
                        placeholder="Please provide enough details for why this access is needed"
                        multiline
                        rowsMax="4"
                        value={comments}
                        onChange={(evt) => {
                            setComments(evt.target.value);
                        }}
                        inputProps={{
                            maxLength: 500,
                        }}
                    />
                </Col>
                <Col xs={12} md={{ span: 6, offset: 2 }} className="text-right">
                    <FormControlLabel
                        control={<CustomCheckbox />}
                        label={"Urgent Request"}
                    />
                </Col>
            </Row>
            {/* Button submit and back */}
            <Row className="py-3">
                <Col xs={6} sm={1} className="text-center">
                    <Button
                        size="large"
                        variant="contained"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        startIcon={<MdArrowBack />}
                    >
                        Back
                    </Button>
                </Col>
                <Col xs={6} sm={{ span: 1, offset: 10 }} className="text-center">
                    <Button
                        size="large"
                        variant="contained"
                        className={decideButtonNextIsDisabled() ? "" : "primary-color"}
                        disabled={decideButtonNextIsDisabled()}
                        onClick={submitOnClickSaveUserOrUpdate}
                        endIcon={<MdArrowForward />}
                    >
                        Submit Request
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state) => ({
    regionsApi: state.catalogs.regions,
    marketsApi: state.catalogs.markets,
    agencysApi: state.catalogs.agencys,
    practicesApi: state.catalogs.practices,
    platformsApi: state.catalogs.platforms,
    clientsApi: state.catalogs.clients,
    clientCodeApi: state.catalogs.clientCode,
    platformOwnershipApi: state.catalogs.platformOwnership,
    isRequestForMe: state.request.isRequestForMe,
    isRequestForInternal: state.request.isRequestForInternal,
    emailSelected: state.request.emailSelected,
    loggedUser: state.request.loggedUser,
    requestUser: state.request.requestUser,
    manager: state.request.manager,
    comments: state.request.comments,
});

export default connect(mapStateToProps, {
    fetchRegionsApi,
    fetchMarketsApi,
    fetchAgencysApi,
    fetchPracticesApi,
    fetchPlatformsApi,
    fetchClientsApi,
    fetchPlatformOwner,
    setClientCodeAndAccount,
    postAccessRequest,
    updateUserData,
    setComments,
    saveNewExternalUser,
    validateSpecialAccessRequest,
    getPlatformDataById,
    getAssetTypeDataByClientId,
})(Step2);
